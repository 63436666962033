<div style="background-color: white" *ngIf="!movil">
  <div class="container">
    <div class="row">
      <div class="col-md mx-auto" align="center" *ngIf="!movil">
        <img
          src="{{ imagenes.logoEpmresa }}"
          alt="Planeta Marte"
          class="card-img-top"
          style="width: 40%"
        />
      </div>

      <div class="col-md mx-auto" align="center">
        <a [href]="sanitize(meetingNumber)" target="_blank">
          <div
            class="picture left"
            style="width: 220px; background-color: WHITE; border-color: WHITE"
            align="center"
          >
            <img
              src="/assets/teams.png"
              alt="Planeta Marte"
              style="background-color: WHITE; border-color: WHITE"
              width="110"
              height="110"
            />
            <br />
            <br />
            <a
              class="boton-zoom"
              [href]="sanitize(meetingNumber)"
              target="_blank"
              style="font-size: 13px"
            >
              <strong>Entrar a la Reunion</strong>
            </a>
          </div>
        </a>
      </div>
      <div class="col-md mx-auto" align="center">
        <img
          src="{{ imagenes.logoConjunto }}"
          alt="Planeta Marte"
          class="card-img-top"
          style="width: 50%"
        />
      </div>
    </div>

    <hr class="my-14" />

    <div class="row">
      <div class="col-md-6 mx-auto justify-content-center" align="center">
        <h1 class="h1">VOTACION</h1>
        <app-votar></app-votar>
      </div>
      <div class="col-md-6 mx-auto" align="center">
        <app-graficas></app-graficas>
      </div>
    </div>
    <br />
    <hr />

    <div class="row">
      <div class="col-md-6 mx-auto" align="center">
        <h1 class="h1">ARCHIVOS</h1>
        <cdk-virtual-scroll-viewport itemSize="200" style="height: 300px;">
          <app-documentos></app-documentos>
        </cdk-virtual-scroll-viewport>
      </div>
      <div class="col-md-6 mx-auto" align="center">
        <h1 class="h1">INFORMACION</h1>
        <div class="card">
          <div class="card-header" style="color: white">
            <strong>{{ tasks.nombres }} {{ tasks.apellidos }}</strong>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <!-- <li class="list-group-item">
                            Torre: {{tasks.torre}}- Apartamento: {{tasks.apartamento}}
                        </li> -->
              <li class="list-group-item">Documento: {{ tasks.documento }}</li>
              <li class="list-group-item">Correo: {{ tasks.correo }}</li>
              <li class="list-group-item">
                Telefono: {{ tasks.numeroCelular }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <hr />

    <div class="row">
      <div class="col-md-12 mx-auto" align="center">
        <h1 style="font-size: 50px">Resultado</h1>
      </div>
    </div>
    <br />
    <br />
    <h1 style="text-align: center">
      <button
        type="button"
        class="btn btn-primary btn-block"
        (click)="actualizarvotaciones()"
      >
        Refrescar
      </button>
    </h1>
    <div class="row" *ngFor="let ids of idVotacion">
      <div class="col-md-9 mx-auto" align="center">
        <app-votacionesusuario [ids]="ids"></app-votacionesusuario>

        <br />
        <br />
      </div>
    </div>
    <div class="linea"></div>
    <br />
  </div>
</div>
<div style="background-color: white"  *ngIf="movil">
  <div class="container">
    <div class="mx-auto" align="center">
      <a [href]="sanitize(meetingNumber)" target="_blank">
        <div
          class="picture left"
          style="width: 220px; background-color: WHITE; border-color: WHITE"
          align="center"
        >
          <img
            src="/assets/teams.png"
            alt="Planeta Marte"
            style="background-color: WHITE; border-color: WHITE"
            width="110"
            height="110"
          />
          <br />
          <br />
          <a
            class="boton-zoom"
            [href]="sanitize(meetingNumber)"
            target="_blank"
            style="font-size: 13px"
          >
            <strong>Entrar a la Reunion</strong>
          </a>
        </div>
      </a>
    </div>
    <div class="mx-auto" align="center">
      <img
        src="{{ imagenes.logoConjunto }}"
        alt="Planeta Marte"
        class="card-img-top"
        style="width: 50%"
      />

      <hr class="my-14" />

      <div class="mx-auto justify-content-center" align="center">
        <h1 class="h1">VOTACION</h1>
        <app-votar></app-votar>
      </div>
      
        <app-graficas></app-graficas>
      
      <br />
      <hr />
      
      <div class="mx-auto" align="center">
        <br />
        <div class="card">
          <div class="card-header" style="color: white">
            <strong>{{ tasks.nombres }} {{ tasks.apellidos }}</strong>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <!-- <li class="list-group-item">
                            Torre: {{tasks.torre}}- Apartamento: {{tasks.apartamento}}
                        </li> -->
              <li class="list-group-item">Documento: {{ tasks.documento }}</li>
              <li class="list-group-item">Correo: {{ tasks.correo }}</li>
              <li class="list-group-item">
                Telefono: {{ tasks.numeroCelular }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mx-auto" align="center">
        <h1 class="h1">ARCHIVOS</h1>
        <cdk-virtual-scroll-viewport itemSize="200"  style="height: 200px;">
          <app-documentos></app-documentos>
        </cdk-virtual-scroll-viewport>
      </div>
      <hr />
      <div class="mx-auto" align="center">
        <h1 style="font-size: 50px">Resultado</h1>
      </div>

      <br />
      <br />
      <h1 style="text-align: center">
        <button
          type="button"
          class="btn btn-primary btn-block"
          (click)="actualizarvotaciones()"
        >
          Refrescar
        </button>
      </h1>
      <div *ngFor="let ids of idVotacion">
        <div class="mx-auto" align="center">
          <app-votacionesusuario [ids]="ids"></app-votacionesusuario>

          <br />
          <br />
        </div>
      </div>
      <div class="linea"></div>
      <br />
    </div>
  </div>
</div>
