<div class="row">
    <div class="col-md-12 mx-auto" align="center">
        
        <div class="chart">
            <canvas baseChart
              [data]="pieChartData"
              [labels]="pieChartLabels"
              [chartType]="pieChartType"
              [options]="pieChartOptions"
              [plugins]="pieChartPlugins"
              [colors]="pieChartColors"
              [legend]="pieChartLegend">
            </canvas>
          </div>
     
          <button mat-button mat-raised-button class="btn btn-primary btn-block btnupdate" (click)="actualizarQou()">Actualizar</button>

        
     
    </div>

</div>