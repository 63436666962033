<div>
    <div>
      <div style="display: block">
        <canvas baseChart
          [datasets]="barChartData"
          [labels]="barChartLabels"
          [options]="barChartOptions"
          [plugins]="barChartPlugins" 
          [legend]="barChartLegend"
          [chartType]="barChartType">
        </canvas>
      </div>
      
      <div class="row">
          <div class="col-md-4 mx-auto" align="center" *ngFor="let listUsers of pporcentaje">
            <table class="table table-bordered table-hover" id="tab_logic">
              <thead>
                <tr >
                  <th class="text-center" style="color: black;">
                    {{listUsers.label}}
                  </th>
                </tr> 
              </thead>
              <tbody>
                <tr id='addr0' style="color: black;">
                  <td>
                  {{listUsers.data}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-4 mx-auto" align="center">
            
          </div>
      </div>
    </div>
  </div>
  <hr>