
<div *ngIf="src; else elseBlock">



        <div id="accordion" style="width: 100%;">
            <div class="col-md-12 mx-auto" align="center" *ngFor="let link of src; index as i; " style="width: 100%;">



                <div class="card">
                    <div class="card-header" style="width: 100%;">
                        <a class="card-link"  download="{{link}}" href="{{link}}"  Target="_blank" style="color: white;">
                            Documento Cargado a la plataforma {{i+1}}
                        </a>
                    </div>
                    <!-- <div id="collapse{{i+1}}" class="collapse" data-parent="#accordion" style="width: 100%;">
                        <div class="card-body" style="width: 300px;">
                            <a href="{{link}}" download="Reporte2Mayo2010" Target="_blank" class="btn btn-dark">
                                Descargar Archivo
                            </a>
                            
                            <pdf-viewer src="{{link}}" [render-text]="true"
                            [original-size]="false"></pdf-viewer>
                        </div>
                    </div> -->
                </div>

                
            </div>
        </div>
    
</div>
<ng-template #elseBlock>
    <div class="col-md-12 mx-auto" align="center">
        <div class="alert alert-dismissible alert-primary">
            <button type="button" class="close" data-dismiss="alert">&times;</button>
            <strong>Archivos</strong> <a href="#" class="alert-link">No hay Archivos cargados</a>.
        </div>
    </div>
</ng-template>