<nav class="navbar navbar-expand-lg navbar-dark bg-primary"   style="border-radius: 0%"
>
  <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
    <div class="navbar-nav ml-auto">
      <ng-container *ngIf="!authService.loggedInUser(); else loggedIn2 " >
        <a class="nav-link" routerLink="usuario" routerLinkActive="active"><img src="/assets/iniciar-sesion.png"  alt=""> Iniciar Sesión</a>
      </ng-container> 
      <ng-template #loggedIn2>     
        <a  style="cursor: pointer;" (click)="authService.logout()"><img src="/assets/cerrar-sesion.png"  alt=""> Cerrar Sesion</a>
      </ng-template>
    </div>
  </div>
</nav>

<div style="background-color: white;" class="">

  <router-outlet></router-outlet>

</div>
