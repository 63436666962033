import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { Router } from '@angular/router';


@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {

  constructor(
    public dialogref: MatDialogRef<ConfirmDialogComponent>,    
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public  data:{title:string,text:string,continue: boolean    } 
    ) { 
      this.dialogref.disableClose = true;

    }

  ngOnInit(): void {
  }

  onClickNo():  void {
    this.dialogref.close();
    if (this.data.continue) {
      this.router.navigate(['/escritorio']);
    }
  }

}
