<div class="row">
  <div class="col-md-12 mx-auto" align="center">
    <h1>Usuarios</h1>
    <cdk-virtual-scroll-viewport itemSize=400 class="container">
      <table class="table table-hover">
        <thead>
          <tr class="table-primary">
            <th scope="col">Nombres</th>
            <th scope="col">Apellidos</th>
            <th scope="col">Torre</th>
            <th scope="col">Apartamento</th>
            <th scope="col">Documento</th>
            <th scope="col">Correo</th>
            <th scope="col">Numero Celular</th>
            <th scope="col">Coeficiente</th>
            <th scope="col">usuario</th>
            <th scope="col">contrasena</th>
            <th scope="col">Guardar</th>
            <th scope="col">Borrar</th>

          </tr>
        </thead>
        <tbody *ngFor="let listUsers of listUser">

          <tr class="table-active">

            <td>{{listUsers.nombres}}</td>
            <td>{{listUsers.apellidos}}</td>
            <td>{{listUsers.torre}}</td>
            <td>{{listUsers.apartamento}}</td>
            <td>{{listUsers.documento}}</td>
            <td>{{listUsers.correo}}</td>
            <td>{{listUsers.numeroCelular}}</td>
            <td>{{listUsers.coefi}}</td>
            <td>{{listUsers.usuario}}</td>
            <td>{{listUsers.contrasena}}</td>
            <td><img src="/assets/disquete.png" alt=""></td>
            <td><img src="/assets/eliminar.png" alt=""></td>


          </tr>

        </tbody>
      </table>
    </cdk-virtual-scroll-viewport>
  </div>
</div>
<div class="linea"></div>

<br>
<br>
<div class="row">
  <div class="col-md-12 mx-auto" align="center">
    <h1>Quorum</h1>
    <app-quorum [id]="id"></app-quorum>
  </div>
</div>
<br>
<br>
<div class="row">
  <div class="col-md-12 mx-auto" align="center">
    <h1>Quorum Estado:</h1>
    <div *ngIf="estadoQuorum; else elseBlock">
      <button color="primary" class="btn btn-primary btn-block" (click)="Desactivar()">desactivar</button>
    </div>
    <ng-template #elseBlock>
      <button color="primary" class="btn btn-primary btn-block" (click)="activar()">Activar</button>
    
    </ng-template>
  </div>
</div>


<div class="linea"></div>
<br>
<br>
<div class="row">
  <div class="col-md-12 mx-auto" align="center">
    <h1>Crear Votacion</h1>
    <app-crearvotacion [id]="id"></app-crearvotacion>

  </div>
</div>
<div class="linea"></div>
<br>
<br>
                    
<h1 style="text-align: center;">Votaciones <button type="button" class="btn btn-default" (click)="actualizarvotaciones()">Refrescar</button> </h1>
<div class="row" *ngFor="let ids of idVotacion" >
  
  <div class="col-md-9 mx-auto" align="center" >
  
    <app-votaciones [ids]="ids"></app-votaciones>
    <br>
  <br>
  </div>
  
</div>
<div class="linea"></div>
<br>
<br>